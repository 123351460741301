<template>
  <div class="container-layout-custom" style="max-width: 1980px;">
    <div class="q-pa-xl full-width" v-if="$route.name === 'reports'">
      <div class="row col-12 q-mb-xl justify-between">
        <div class="text-h4">Reports</div>
      </div>
      <div class="row q-gutter-md">
        <q-card flat bordered class="reportCard cursor-pointer bg-lmsBackground" v-for="(report, i) in displayReports" :key="i" @click="goToReport(report.type)">
          <q-card-section>
            <div class="text-h6">{{ report.name }}</div>
          </q-card-section>
          <q-card-section class="reportCard__description">
            {{ report.description }}
          </q-card-section>
          <q-card-actions class="reportCard__actions">
            <q-btn flat @click="goToReport(report.type)">View Report</q-btn>
          </q-card-actions>
        </q-card>
      </div>
      <ScheduledReportsTable />
    </div>
    <router-view v-else></router-view>
    <q-dialog v-model="scheduledReportModal">
      <ScheduledReportForm :propReport="reportToEdit" @closeForm="scheduledReportModal = false"/>
    </q-dialog>
  </div>
</template>

<script>
import ScheduledReportForm from 'components/ScheduledReportForm'
import ScheduledReportsTable from 'components/ScheduledReportsTable'

export default {
  name: 'ReportsPage',
  components: { ScheduledReportForm, ScheduledReportsTable },
  data () {
    return {
      reports: [
        { name: 'Logins', type: 'login', description: 'Report on the number of times users have logged in over a period of time. Filter by Location or User Group.' },
        { name: 'Modules', type: 'modules', description: 'Report on the number of completed modules over a period of time.' },
        { name: 'Assigned Modules', type: 'assignment', description: 'Report on the number of completed module assignments over a period of time.' },
        { name: 'Videos', type: 'media', description: 'Report on the number of times or the amount of time users have watched videos over a period of time.' },
        { name: 'Imported Modules', type: 'importedModules', description: 'View the overall percentage of modules imported from the Global Library', allowedRoles: ['||', 'master-admin', 'site-admin'] }
      ],
      reportToEdit: {},
      scheduledReportModal: false,
      scheduledReports: []
    }
  },
  computed: {
    displayReports () {
      return this.reports.filter(report => !report.allowedRoles || this.$isAllowed(report.allowedRoles, true))
    }
  },
  methods: {
    goToReport (type) {
      if (type === 'importedModules') {
        this.$router.push({ name: 'importedModules' })
      } else {
        this.$router.push({ name: 'report', params: { type: type } }).catch(() => {})
      }
    },
    editScheduledReport (report) {
      this.reportToEdit = { ...report }
      this.scheduledReportModal = true
    }
  }
}
</script>

<style lang="stylus" scoped>
  .reportCard
    width 100%
    max-width 300px
    transition .3s
    &:hover
      box-shadow 0 7px 8px -2px rgba(0,0,0,0.2), 0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12) !important
      border-color transparent
      transform translateY(-5px)
  .reportCard__description
    margin-bottom 3rem
  .reportCard__actions
    position absolute
    bottom 0
    left 50%
    transform translateX(-50%)

    button
      width 115px
</style>
