<template>
  <div class="q-my-xl row">
    <div class="text-h6 col-12">Scheduled Reports</div>
    <q-list class="row full-width">
      <q-item class="row full-width">
        <q-item-section class="col-3">
          <div class="text-caption text-weight-bold">Report Name</div>
        </q-item-section>
        <q-item-section class="col-3">
          <div class="text-caption text-weight-bold">Type</div>
        </q-item-section>
        <q-item-section class="col-3">
          <div class="text-caption text-weight-bold">Recurrence</div>
        </q-item-section>
        <q-item-section class="col-3">
          <div class="text-caption text-weight-bold text-center">Actions</div>
        </q-item-section>
      </q-item>
      <q-item v-for="(report, i) in scheduledReports" :key="i" class="row full-width">
        <q-item-section class="col-3">
          <div class="text-body">{{ report.label }}</div>
        </q-item-section>
        <q-item-section class="col-3">
          <div class="text-body" style="text-transform: capitalize">{{ report.type }}</div>
        </q-item-section>
        <q-item-section class="col-3">
          <div class="text-body" style="text-transform: capitalize">{{ report.recurring_duration }}</div>
        </q-item-section>
        <q-item-section class="col-1">
          <!-- <q-btn flat color="primary" label="Manage Subscribers" @click="showSubscribers(report)" /> -->
          <q-btn round flat color="primary" icon="group_add" @click="showSubscribers(report)">
            <q-tooltip>Manage Subscribers</q-tooltip>
          </q-btn>
        </q-item-section>
        <q-item-section class="col-1">
          <!-- <q-btn flat color="primary" label="Details" @click="showDetails(report)" /> -->
          <q-btn round flat color="primary" :icon="isOwner(report) ? 'edit' : 'info'" @click="showDetails(report)">
            <q-tooltip v-if="isOwner(report)">Edit Report</q-tooltip>
            <q-tooltip v-else>Report Details</q-tooltip>
          </q-btn>
        </q-item-section>
        <q-item-section class="col-1">
          <q-btn v-if="isOwner(report)" round flat color="primary" :icon="isActive(report) ? 'pause' : 'play_arrow'" @click="toggleActiveStatus(report, i)">
            <q-tooltip>{{ isActive(report) ? 'Pause' : 'Resume' }} Report</q-tooltip>
          </q-btn>
          <q-btn v-else round flat color="primary" :icon="isSubscribed(report) ? 'check' : 'add'" @click="toggleSubscription(report, i)">
            <q-tooltip>{{ isSubscribed(report) ? 'Unsubscribe From' : 'Subscribe To' }} Report</q-tooltip>
          </q-btn>
          <!-- <q-btn v-if="isOwner(report)" flat color="primary" :label="isActive(report) ? 'Pause' : 'Resume'" @click="toggleActiveStatus(report, i)"/>
          <q-btn v-else flat color="primary" :label="isSubscribed(report) ? 'Unsubscribe' : 'Subscribe'" @click="toggleSubscription(report, i)"/> -->
        </q-item-section>
      </q-item>
    </q-list>

    <q-dialog v-model="showSubscribersModal" @hide="closeModal(null)">
      <ManageReportSubscribers v-if="selectedReport" :propReport="selectedReport" @closeForm="closeModal"/>
    </q-dialog>

    <q-dialog v-model="showDetailsModal" @hide="closeModal(null)">
      <ScheduledReportForm v-if="selectedReport" :propReport="selectedReport" @closeForm="closeModal"/>
    </q-dialog>
  </div>
</template>

<script>
import ScheduledReportForm from 'components/ScheduledReportForm'
import ManageReportSubscribers from 'components/ManageReportSubscribers'

export default {
  components: { ScheduledReportForm, ManageReportSubscribers },
  data () {
    return {
      scheduledReports: [],
      selectedReport: null,
      showDetailsModal: false,
      showSubscribersModal: false
    }
  },
  methods: {
    showDetails (report) {
      this.selectedReport = report
      this.selectedReport.is_active = this.isActive(report)
      this.showDetailsModal = true
    },
    showSubscribers (report) {
      this.selectedReport = report
      this.showSubscribersModal = true
    },
    isOwner (report) {
      return report.user_uid === this.$store.state.auth.user.uid
    },
    isSubscribed (report) {
      let users = JSON.parse(report.settings).user_uids
      return users ? users.includes(this.$store.state.auth.user.uid) : false
    },
    isActive (report) {
      if (report.is_active === '1' || report.is_active === 'true' || report.is_active === true) {
        return true
      } else {
        return false
      }
    },
    async toggleSubscription (report, index) {
      this.$q.loading.show()
      try {
        let res = await this.$axios.post(`${this.$consts.SCHEDULED_REPORTS_URL}/toggleSubscribe/${report.uid}`)
        this.scheduledReports.splice(index, 1, res)
        this.$q.loading.hide()
        this.$successMsg(JSON.parse(res.settings).user_uids.includes(this.$store.state.auth.user.uid) ? 'Subscribed' : 'Unsubscribed')
      } catch (e) {
        this.$q.loading.hide()
        this.$failureMsg()
      }
    },
    async toggleActiveStatus (report, index) {
      this.$q.loading.show()
      try {
        let res = await this.$axios.post(`${this.$consts.SCHEDULED_REPORTS_URL}/update/${report.uid}`, { is_active: !this.isActive(report) })
        this.scheduledReports.splice(index, 1, res)
        this.$q.loading.hide()
        this.$successMsg(this.isActive(res) ? 'Scheduled Report Resumed' : 'Paused Scheduled Report')
      } catch (e) {
        this.$q.loading.hide()
        this.$failureMsg()
      }
    },
    closeModal (report) {
      this.showDetailsModal = false
      this.showSubscribersModal = false
      this.selectedReport = null
      if (!report) return

      let index = this.scheduledReports.findIndex(obj => obj.uid === report.data.uid)
      switch (report.type.toLowerCase()) {
        case 'update':
          // find index of report and update
          this.scheduledReports.splice(index, 1, report.data)
          break
        case 'add':
          // add to end or beginning of array
          this.scheduledReports.unshift(report.data)
          break
        case 'delete':
          // remove from array
          this.scheduledReports.splice(index, 1)
          break
      }
    }
  },
  async created () {
    let res = await this.$axios.get(`${this.$consts.SCHEDULED_REPORTS_URL}/all`)
    this.scheduledReports = res
  }
}
</script>
